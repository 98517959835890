import React, {useState} from 'react';
import Scrollspy from 'react-scrollspy';
import Navigation from '../components/Navigation';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import overLayPic from '../assets/images/overlay.png';
import Contact from '../components/Form'
import Modal from 'react-modal';


const sections = [
  { id: 'one', bg: require('../assets/images/pic06.png') },
  { id: 'two', bg: require('../assets/images/pic02.jpg') },
  { id: 'three', bg: require('../assets/images/pic03.jpg') },
];

const backgrounds = [
  `url('${require('../assets/images/SG-backgrounds/floral 3 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 4 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 5 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 8 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 9 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 10 3000x3000.jpg')}')`,
]
const backgroundIndex =  Math.floor((Math.random() *6) + 0);
const background = backgrounds[backgroundIndex];



const IndexPage = () => {
  
  const [individualModalOpen, setIndividualModalOpen] = useState(false);
  const [couplesModalOpen, setCouplesModalOpen] = useState(false);
  const [meditationModalOpen, setMeditationModalOpen] = useState(false);
  const [yogaModalOpen, setYogaModalOpen] = useState(false);

 
  
  return <Layout background={background}> 
  
    <Navigation color={backgroundIndex == 0? null : "light"} />

    <Modal
      isOpen={individualModalOpen}
      contentLabel="Individual Modal"
      onRequestClose={()=>setIndividualModalOpen(false)}
      closeTimeoutMS={250}
    >
      <div style={{display:"flex", justifyContent:"flex-end", background:"#2e4861", padding:"15px 20px 0px 0px" }}>
        <span onClick={()=>setIndividualModalOpen(false)} className="btn-icon" style={{color: "white", cursor:"pointer"}}>
            <span className="icon fas fa-times" style={{fontSize:"40px"}} />
        </span>
      </div>
      <header  style={{background:"#2e4861", textAlign:"center", paddingBottom:"9px"}}>
        <h2 style={{color:"white"}}>Individual COUNSELING</h2>
      </header>
      <div style={{padding: "20px"}}>
        <p style={{textAlign:"center"}}>I welcome who you are, where you are, and what has brought you into therapy. In one-on-one counseling you are invited to explore your past, present, and future. Below is a list of common topics that I often explore with clients. </p>
        <div style={{display:"flex", justifyContent:"space-around"}}>
          <p style={{textAlign:"center"}}>
          Anxiety, Stress, and Fear <br />
          Depression and Sadness<br />
          Relationship Struggles<br />
          Family Dynamics<br />
          Trauma
          </p>
          <p style={{textAlign:"center"}}>
            Self-love <br />
            Shame & Guilt <br />
            Faith & Spiritual Growth <br />
            Self-esteem/Self-acceptance <br />
            Understanding Self & Purpose 
          </p>
        </div>

      </div>
    </Modal>

    <Modal
      isOpen={couplesModalOpen}
      contentLabel="Couples Modal"
      onRequestClose={()=>setCouplesModalOpen(false)}
      closeTimeoutMS={250}
    >
      <div style={{display:"flex", justifyContent:"flex-end", background:"#274863", padding:"15px 20px 0px 0px" }}>
        <span onClick={()=>setCouplesModalOpen(false)} className="btn-icon" style={{color: "white", cursor:"pointer"}}>
            <span className="icon fas fa-times" style={{fontSize:"40px"}} />
        </span>
      </div>
      <header  style={{background:"#274863", textAlign:"center", paddingBottom:"9px"}}>
        <h2 style={{color:"white"}}>COUPLES COUNSELING</h2>
      </header>
      <div style={{padding: "20px"}}>
        <p style={{textAlign:"center"}}>Relationships are easily complicated; especially when two people have joined their lives together. When a couple first gets together there is often a sense of joy, bliss, playfulness, and excitement. Sometimes this gets lost along the way. Priorities begin to shift, other responsibilities take over, dating one another is placed on the back burner, and now somehow even communicating feels difficult.</p>
        <p style={{textAlign:"center"}}>As a therapist, I see my role as inviting you both to a sense of mutuality: learning to truly listen to your partner, increase an understanding of one another's perspective, and working towards rebuilding the partnership of joy that you both desire.</p>
        
      </div>
    </Modal>
   
    <Modal
      isOpen={meditationModalOpen}
      
      contentLabel="Meditation Modal"
      onRequestClose={()=>setMeditationModalOpen(false)}
      closeTimeoutMS={250}
    >
      <div style={{display:"flex", justifyContent:"flex-end", background:"#6f3056", padding:"15px 20px 0px 0px" }}>
        <span onClick={()=>setMeditationModalOpen(false)} className="btn-icon" style={{color: "white", cursor:"pointer"}}>
            <span className="icon fas fa-times" style={{fontSize:"40px"}} />
        </span>
      </div>
      <header  style={{background:"#6f3056", textAlign:"center", paddingBottom:"9px"}}>
        <h2 style={{color:"white"}}>Meditation</h2>
      </header>
      <div style={{padding: "20px"}}>
        <p style={{textAlign:"center"}}>Meditation is focusing the mind on one particular thing; whether that be a thought, emotion, person, visual guide, sensation, activity, or object. Meditation and mindfulness helps to train the mind to remain calm and focused. It reduces stress and anxiety as the mind learns to be in the present moment rather than wandering to the past or future.</p>
        <p style={{textAlign:"center"}}>Meditations often work well in conjunction with therapy, suited to your specific needs.
Meditation packages are also offered, where I create a series of meditations specific for you.</p>
        
      </div>
    </Modal>
   
    <Modal
      isOpen={yogaModalOpen}
      
      contentLabel="Yoga Modal"
      onRequestClose={()=>setYogaModalOpen(false)}
      closeTimeoutMS={250}
    >
      <div style={{display:"flex", justifyContent:"flex-end", background:"#6f3056", padding:"15px 20px 0px 0px" }}>
        <span onClick={()=>setYogaModalOpen(false)} className="btn-icon" style={{color: "white", cursor:"pointer"}}>
            <span className="icon fas fa-times" style={{fontSize:"40px"}} />
        </span>
      </div>
      <header  style={{background:"#6f3056", textAlign:"center", paddingBottom:"19px"}}>
        <h2 style={{color:"white"}}>Yoga</h2>
      </header>
      <div style={{padding: "20px"}}>
        <p style={{textAlign:"center"}}>The word yoga means union. Union of self: your body, mind, breath, and spirit. Yoga often becomes a meditative movement as you make these connections through physical postures, breathwork, and inner awareness. Yoga often becomes a modality of healing and growing to love the self.</p>
        <p style={{textAlign:"center"}}>One-on-one yoga sessions are specific to your goals, whether that be lengthening in the physical postures, increasing an understanding of the mind-body connection, or simply breathing deeply.</p>
        <p style={{textAlign:"center"}}>Private group sessions are also available upon inquiry.</p>
      </div>
    </Modal>
   
    
    <Header logoDark={backgroundIndex == 0} />
    
    <section id="one" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt="" />
        </span>
        <div className="content">
          <img src={require("../assets/images/sp2.jpg")} style={{width:"350px", borderRadius:"1%"}} alt="Samantha Piro portriait"/>
          <header className="major">
            <h2>Hi, i'm Samantha Piro</h2>
          </header>
            
            <p style={{textAlign:"left"}}>I support people on their healing journey by inviting them to create a sense of meaning and purpose for one’s own life through cultivating a sense of self.</p>
            <p style={{textAlign:"left"}}><span style={{fontStyle:"italic"}}>Cultivate the Self</span> means to increase or grow one’s own self awareness, insight, and understanding into who you are and who you want to be in the world. By taking a holistic approach, I invite clients to not only think about their own thoughts, but to also feel their emotions, tap into somatic sensations, and embody the love and healing that each human deeply deserves.</p>
            <div className="row">
                
                <div className="col-6 col-12-medium">
                  <a href="/about" className="button primary">Read My Story</a>
                </div>
                
                <Scroll type="id" element="two">
                  <div className="col-6 col-12-medium">
                    <a href="/#two" className="button primary" style={{paddingLeft:"3.5em", paddingRight:"3.5em"}}>Services</a>
                  </div>
                </Scroll>
              </div>
            
        </div>
        <Scroll type="id" element="two">
          <a href="#two" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="two" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[1].bg} alt="background" />
        </span>
        <div className="content">
          <header className="major">
            <h2>THERAPY SERVICES</h2>
          </header>
          <p style={{textAlign:"left"}}>I provide a safe, comfortable space for you to discuss and explore your thoughts and feelings, while accepting you just as you are. I view therapy as an opportunity for people to heal, grow, and understand more of themselves as well as the world around them, allowing them the freedom to be the person they want to be.</p>
          <p style={{textAlign:"left"}}>I see change happening through mindful awareness, increased insight, and taking action. We will work together to explore these three steps in order to create the change you seek.</p>
          <p style={{textAlign:"left"}}>Click the icons below for more information.</p>
          <ul className="icons-grid">
            <li 
              
              onClick={()=>setIndividualModalOpen(true)}
            >
              <span className="icon major fas fa-user" />
              <h3>Individual Counseling</h3>
            </li>
            <li 
              
              onClick={()=>setCouplesModalOpen(true)}
            >
              <span className="icon major fas fa-user-friends" />
              <h3>Couples Counseling</h3>
            </li>
            <li 
              
              onClick={()=>setYogaModalOpen(true)}
            >
              <img style={{width:"4em"}} src={require("../assets/yoga.svg")} alt="yoga icon"/>
              <h3>Yoga</h3>
            </li>
            <li 
              
              onClick={()=>setMeditationModalOpen(true)}
            >
              <img src={require("../assets/untitled.svg")} alt="meditation icon" />
              <h3>Meditation</h3>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="footer">
          <a href="#footer" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="footer">
      <div className="container">
        <header className="major">
          <h2>Get in touch</h2>
        </header>
        <Contact formSpree="https://formspree.io/xoqlowlr" />
        <p><a class="phone-link" href="tel:12489068547">Or call today! 248.906.8547</a></p>
      </div>
      <Footer />
    </section>
    <Scrollspy
      items={sections.map(s => s.id)}
      currentClassName="active"
      offset={50}
      componentTag={'div'}
    >
      {sections.map(s => {
        return (
          <div
            key={s.id}
            className="main-bg"
            id={`${s.id}-bg`}
            style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
          />
        );
      })}
    </Scrollspy>
  </Layout>
}

export default IndexPage;
