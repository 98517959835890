import React from 'react';
import Scroll from './Scroll';

export default function Header(props) {
  const logo = props.logoDark?  require('../assets/images/SG Full Logo/SG Full Logo - Night Sky- HiRes.png') : require('../assets/images/SG Full Logo/SG Full Logo - White- HiRes.png')
  return (
    <section id="header">
      <header className="major">
        <a href="#home" className="goto-next "></a>
        <h1><img src={logo} style={{maxWidth: "850px", width:"80%"}} alt="Sacred Garden | Therapy in Rochester, MI 48307"/></h1>
        <p style={{...(props.logoDark?{color:"#284862"}:{})}}>Cultivate the <span>Self</span></p>
        <div style={{display:"flex", justifyContent:"center"}}>
          <p style={{fontSize: "1em", fontFamily:"Montserrat", fontWeight:"lighter", width: "75%",...(props.logoDark?{color:"#545454"}:{})}}>holistic psychotherapy + counseling for individuals and couples seeking to develop a greater sense of the self</p>
        </div>
      </header>
      <div className="container">
        <ul className="actions special">
          <li>
              <div className="row">
                <Scroll type="id" element="one">
                <div className="col-6 col-12-medium">
                  <a href="#one" className="button primary">
                    Begin
                  </a>
                </div>
                </Scroll>
                <Scroll type="id" element="footer">
                <div className="col-6 col-12-medium">
                  <a href="#three" className="button primary">
                    Contact Me
                  </a>
                </div>
                </Scroll>
              </div>
              
            
          </li>
        </ul>
      </div>
    </section>
  );
}
